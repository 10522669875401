// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-painting-and-decorating-js": () => import("./../src/pages/painting-and-decorating.js" /* webpackChunkName: "component---src-pages-painting-and-decorating-js" */),
  "component---src-templates-maintenance-template-maintenance-template-js": () => import("./../src/templates/maintenance-template/maintenance-template.js" /* webpackChunkName: "component---src-templates-maintenance-template-maintenance-template-js" */),
  "component---src-templates-painting-and-decorating-template-painting-and-decorating-template-js": () => import("./../src/templates/painting-and-decorating-template/painting-and-decorating-template.js" /* webpackChunkName: "component---src-templates-painting-and-decorating-template-painting-and-decorating-template-js" */)
}

